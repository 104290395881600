.half-width {
  width: 50%;
}

.width-20 {
  width: 20%;
}

.popoverList {
  overflow: auto;
  max-height: 40vh;
}

.cursor-pointer {
  cursor: pointer;
}
